<template>
  <div>
    <!-- filter invoices -->
    <div class="container-alb filter-mobile">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td>
            <img src="@/assets/images/filter.svg" />
            <span class="mobile-show margin-left-5">{{
              $t("invoicesFilterTitle")
            }}</span>
          </td>
          <td>
            {{ $t("invoicesFilterProduct") }}
            <select class="filtrare" @change="productSelected">
              <option v-for="(entry, index) in affiliate.products" :key="index" :value="entry.brand"
                :selected="isSelectedProduct(entry.brand)">{{ entry.name }}</option>
            </select>
          </td>
          <td>
            <form @submit.prevent="
                                                    {
              return false;
            }
              ">
              {{ $t("cardTransactionFilterSubsidiary") }}
              <multiselect v-model="selectedWorkingPointAddress" :options="workingPointAddress"
                :placeholder="$t('selectLabel')" select-label="" selected-label="" deselect-label=""
                openDirection="bottom" track-by="address" :custom-label="customLabel" :loading="isLoading"
                @input="subsidiarySelected()"></multiselect>
            </form>
          </td>
          <td>
            {{ $t("invoicesFilterStatus") }}
            <select class="filtrare" @change="invoiceStatusSelected">
              <option value="A" selected>{{ $t("selectLabel") }}</option>
              <option v-for="(entry, index) in invoiceStatuses" :key="index" :value="entry.value"
                :selected="isSelectedInvoiceStatus(entry.value)">
                {{ $t("invoiceStatus_" + entry.value) }}
              </option>
            </select>
          </td>
          <td>
            {{ $t("invoicesFilterPeriod") }}
            <date-range class="invoices-filter" @close="periodFilterChanged" :time="this.filters.period" />
          </td>
        </tr>
      </table>
    </div>

    <!-- <div class="container-alb" v-if="(userRole == 'AFF' || userRole == 'AFA') 
      && (this.filters.product == 'dejun' || this.filters.product == 'cadou' || this.filters.product == 'social')">
      <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
        <tr>
          <td>
            <strong>{{ $t("invoiceAffiliateOugModel") }}:</strong>
          </td>
          <td>
            <a class="status-background background-active" @click="downloadModel">{{ $t("invoiceAffiliatDownloadModel")
            }}</a>
          </td>
        </tr>
      </table>
    </div> -->

    <!-- pending invoices -->
    <div class="container-alb">
      <div class="table-header-wrapper d-flex align-items-baseline justify-content-md-between flex-md-row flex-column">
        <div class="left">
          <h3 class="table-title">{{ $t("pendingInvoicesTitle") }}</h3>
        </div>
        <div class="text-center center" style="">
          <strong v-if="parseFloat(filterDashboardInfos(0, 'BALANCE')) > 0">{{
            $t("balanceCLabelTotal")
          }}</strong>
          <strong v-else>{{ $t("balanceDLabelTotal") }}</strong>
          <span :class="parseFloat(filterDashboardInfos(0, 'BALANCE')) > 0
            ? 'culoare-13'
            : 'culoare-15'
            ">: {{ parseFloat(filterDashboardInfos(0, 'BALANCE')) > 0 ? parseFloat(filterDashboardInfos(0, 'BALANCE')) : filterDashboardInfos(0, "BALANCE_PAID") }}
            {{ $t("currency") }}</span>
        </div>
        <!-- <div class="text-center center" style="">
          <strong v-if="parseFloat(filterDashboardInfos(this.filters.product, 'BALANCE')) > 0">{{
            $t("balanceCLabelProduct", { product: this.selectedProduct.name })
          }}</strong>
          <strong v-else>{{ $t("balanceDLabelProduct", { product: this.selectedProduct.name }) }}</strong>
          <span :class="parseFloat(filterDashboardInfos(this.filters.product, 'BALANCE')) > 0
            ? 'culoare-13'
            : 'culoare-15'
            ">: {{ parseFloat(filterDashboardInfos(this.filters.product, 'BALANCE')) > 0? parseFloat(filterDashboardInfos(this.filters.product, 'BALANCE')) : filterDashboardInfos(this.filters.product, "BALANCE_PAID") }}
            {{ $t("currency") }}</span>
        </div> -->
        <div class="right">
          <a v-if="parseFloat(filterDashboardInfos(0, 'BALANCE')) > 0"
            class="status-background background-will-expire mr-sm-2 mb-2" @click="selectAllInvoicesForPay()">
            <span>
              {{
                $t("payInvoiceIntegralButtonLabel") +
                " (" +
                filterDashboardInfos(0, "BALANCE") +
                " " +
                $t("currency") +
                ")"
              }}
            </span>
          </a>
          <a class="status-background background-active" @click="showInvoicesForPay()">
            <span>
              {{
                $t("payInvoiceButtonLabel") +
                "&nbsp;(" +
                this.selectedInvoices.length +
                ")"
              }}
            </span>
          </a>
        </div>
      </div>
      <br />

      <InvoiceTableDesktop :company="this.affiliate" :companyInvoices="this.affiliatePendingInvoices" :pending="true"
        :userRoleInvTable="this.userRole" @change="sortBy(sortField, sortDirection, 'pending')"
        @click="selectPendingInvoice" ref="childComponent" @model="downloadModel" />
      <!--  -->

      <div class="error-wrapper border-box" v-if="this.affiliatePendingInvoices &&
        this.affiliatePendingInvoices.length === 0
        ">
        <p>{{ $t("noInvoicesMessage") }}</p>
      </div>
    </div>

    <!-- invoice history -->
    <div class="container-alb">
      <div class="table-header-wrapper">
        <div class="left">
          <h3 class="table-title">
            {{ $t("lastInvoicesTitle") }}
            <img class="download" @click="getInvoicesInArchive" src="@/assets/images/download.svg" />
          </h3>
        </div>
      </div>
      <br />
      <InvoiceTableDesktop :company="this.affiliate" :companyInvoices="this.affiliateInvoices" :pending="false"
        :userRoleInvTable="this.userRole" @change="sortBy(sortField, sortDirection)" @click="selectPendingInvoice"
        @model="downloadModel" />
      <!-- @change="invoiceForPayChecked($event.target, invoice)" -->

      <div class="pagination-wrapper border-box">
        <b-pagination v-if="this.affiliateInvoices" v-model="currentPage" :total-rows="this.invoicesCount"
          :per-page="perPage" size="sm"></b-pagination>
      </div>

      <div class="error-wrapper border-box" v-if="this.affiliateInvoices && this.affiliateInvoices.length === 0">
        <p>{{ $t("noInvoicesMessage") }}</p>
      </div>
    </div>
    <div id="modal_popup_pay" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="resetAndClose()" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div class="">
          <h3 class="table-title">{{ $t("pendingInvoicesPay") }}</h3>
          <br />
          <div class="pay_content scroll-window" >
            <table cellpadding="10" cellspacing="0" width="95%" class="hover-table invoices-table " align="center" >
              <thead>
                <tr>
                  <td valign="middle"></td>
                  <td valign="middle">
                    <strong>{{ $t("invoiceNumberHeader") }}</strong>&nbsp;
                  </td>
                  <td class="priority-1" valign="middle">
                    <strong>{{ $t("invoiceDateHeader") }}</strong>&nbsp;
                  </td>
                  <td class="priority-3" valign="middle">
                    <strong>{{ $t("invoiceAmountHeader") }}</strong>&nbsp;
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-if="this.selectedInvoices.length === 0">
                  <td colspan="12">
                    <p>{{ $t("noInvoiceForPay") }}</p>
                  </td>
                </tr>
                <template v-for="(invoice, index) in selectedInvoices">
                  <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                    <td>
                      <input type="checkbox" checked v-model="selectedInvoicesForPay" :value="invoice" @change="
                        invoiceForPayChecked(invoice, $event.target.checked)
                        " />
                    </td>
                    <td valign="middle">
                      <a class="detailsOrderMobile priority-show-5 display-inline-block"
                        @click="selectInvoiceMobile(index)">
                        <font-awesome-icon icon="minus-circle" v-if="detailsMobile && invoice === invoiceSelectedMobile
                          " />
                        <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                      </a>
                      <strong>{{ invoice.invoice }}</strong>
                    </td>
                    <td valign="middle" class="priority-1">
                      {{ displayDate(invoice.date) }}
                    </td>
                    <td valign="middle" class="priority-3">
                      <span>{{ invoice.amount }} {{ $t("currency") }}</span>
                    </td>
                  </tr>
                  <tr :key="index + selectedInvoices.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                    v-show="detailsMobile && invoice === invoiceSelectedMobile">
                    <td colspan="12">
                      <p class="priority-show-1">
                        <strong>{{ $t("invoiceDateHeader") }} : &nbsp;&nbsp;</strong>
                        <span>{{ invoice.date }}</span>
                      </p>
                      <p class="priority-show-3">
                        <strong>{{ $t("invoiceAmountHeader") }} : &nbsp;&nbsp;</strong>
                        <span>{{ invoice.amount }} {{ $t("currency") }}</span>
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <br />
          <div class="balance">
            <input type="checkbox" checked v-model="balancePaidCheck" :value="balancePaidCheck"
              @change="getTotalAmountWithBalancePaid()" />
            <span class="ml-1 mr-1">{{ $t("balancePaidLabel") }}: </span>
            <span>{{ filterDashboardInfos(0, "BALANCE_PAID") }}
              {{ $t("currency") }}</span>
          </div>
          <br />
          <div class="text-right">
            <strong>{{ $t("totalPayment") }}: &nbsp;</strong>
            <!-- <strong>{{ this.getTotalPayment }} {{ $t("currency") }}</strong> -->
            <input type="text" v-model="totalAmount" class="input-add-beneficiary background-transparent width-auto"
              @keyup="updateInput()" />
          </div>

          <br />
          <a class="notification-action" @click="payInvoices()">
            {{ $t("payInvoiceButtonLabel") }}
          </a>
        </div>
        <br />
        <div v-if="totalAmountIsNotEnough">
          <span class="culoare-13 d-block">{{ $t("amountIsNotEnough") }}</span>
          <br />
        </div>
        <div v-if="totalAmountCantBePaid">
          <span class="culoare-13 d-block">{{ $t("amountCantBePaid") }}</span>
          <br />
        </div>
        <div class="banner">
          <img :src="require('@/assets/images/netopia_banner_gradient.jpg')" />
        </div>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')"></p>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="paymentInvoicesError">
          <p v-if="paymentInvoicesError.error_code">
            {{
              $t(paymentInvoicesError.error_code, {
                name: paymentInvoicesError.description
              })
            }}
          </p>
          <p v-else>{{ $t("errorRedirectPaymentMessage") }}</p>
        </div>
        <div v-else>
          <p>{{ $t("redirectPaymentMessage") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_paidSuccess" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_paidSuccess', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="payment">
          <h3>{{ $t("paidStatus_" + payment.status) }}</h3>
        </div>

        <div v-if="payment && payment.errorCode !== 0">
          <p>{{ $t("paidErrorMessage_" + payment.errorCode) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceTableDesktop from "@/components/company/InvoiceTableDesktop.vue";
import utils from "@/plugins/utils.js";

import DateRange from "@/components/DateRange.vue";
import httpServiceUser from "@/http/http-user.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceCustomer from "@/http/http-customer.js";
import { BPagination } from "bootstrap-vue";
import Cookies from "js-cookie";
import moment from "moment";
import Multiselect from "vue-multiselect";
import { saveAs } from "file-saver";

export default {
  props: {
    affiliate: Object,
    userRole: String,
    user: Object,
    msg: String
  },
  components: {
    InvoiceTableDesktop,
    DateRange,
    "b-pagination": BPagination,
    Multiselect
  },
  data() {
    return {
      invoiceError: null,
      affiliatePendingInvoices: null,
      affiliateInvoices: null,
      tempInvoices: null,
      filters: {
        product: "dejun",
        invoiceStatus: "A",
        period: null,
        subsidiary: "A"
      },
      invoiceStatuses: [
        { value: "L", description: "Paid" },
        { value: "U", description: "Unpaid" },
        { value: "O", description: "Overdue" }
      ],
      invoicesCount: 10,
      perPage: 10,
      currentPage: 1,
      sortField: "date",
      sortDirection: "desc",
      subsidiaries: null,
      selectedProduct: null,
      invoiceForPay: null,
      workingPointAddress: [],
      selectedWorkingPointAddress: "",
      selectedInvoices: [],
      selectedInvoicesForPay: [],
      invoiceSelectedMobile: null,
      detailsMobile: null,
      paymentInvoicesError: null,
      paymentInvoicesSuccess: false,
      totalAmount: "0.00",
      payment: null,
      isLoading: false,
      totalAmountIsNotEnough: false,
      totalAmountCantBePaid: false,
      paramsTest: {
        env_key: "",
        data: ""
      },
      seconds: 5,
      foo: null,
      dashboardInfos: [],
      dashboardError: null,
      balancePaidCheck: true
    };
  },
  methods: {
    productSelected(event) {
      this.filters.product = event.target.value;
      this.getAffiliateInvoices("pending");
      this.getAffiliateInvoices();
      this.getAddressPoints("both", this.filters.product);
      // this.getDashboardGeneralInfos(this.filters.product);
    },
    invoiceStatusSelected(event) {
      this.filters.invoiceStatus = event.target.value;
      this.getAffiliateInvoices();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getAffiliateInvoices();
    },
    subsidiarySelected() {
      if (this.selectedWorkingPointAddress === null) {
        this.filters.subsidiary = "A";
      } else {
        this.filters.subsidiary = this.selectedWorkingPointAddress.codcom;
      }
      this.getAffiliateInvoices("pending");
      this.getAffiliateInvoices();
      // this.getDashboardGeneralInfos(this.filters.product);
    },
    isSelectedProduct(product) {
      return product === this.filters.product;
    },
    isSelectedInvoiceStatus() {
      return status === this.filters.invoiceStatus;
    },
    isSelectedSubsidiary(subsidiary) {
      return subsidiary === this.filters.subsidiary;
    },
    sortBy(sortField, sortDirection, status) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getAffiliateInvoices(status);
    },
    getAffiliateInvoices(status) {
      this.affiliateInvoices = null;
      if (status === "pending") {
        this.affiliatePendingInvoices = null;
      }
      var invoicesData =
        "?type=" + "affiliate" ;
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          invoicesData += "&invoice_group=1";
        }
      }
      if (this.filters.subsidiary !== "A") {
        invoicesData += "&codall=" + this.filters.subsidiary;
      }

      if (status !== "pending") {
        invoicesData +=
          "&start_date=" +
          moment(this.filters.period[0]).format("YYYYMMDD") +
          "&end_date=" +
          moment(this.filters.period[1]).format("YYYYMMDD") + 
          "&brand=" + this.filters.product;
      }
      if (status === "pending") {
        invoicesData += "&paid=U,V";
        invoicesData += "&per_page=100";
      } else {
        if (this.filters.invoiceStatus !== "A") {
          invoicesData += "&paid=" + this.filters.invoiceStatus;
        } else {
          invoicesData += "&paid=L,R,P";
        }
        invoicesData += "&per_page=" + this.perPage;
      }
      invoicesData +=
        "&page=" + this.currentPage + "&sort=" + this.sortDirection;
      if (this.sortDirection === "desc") {
        invoicesData += "&desc=" + this.sortField;
      } else {
        invoicesData += "&asc=" + this.sortField;
      }
      httpServiceUser.userService
        .getCompanyInvoices(
          this.affiliate.company_id,
          invoicesData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(error => {
          this.invoiceError = {
            code: error.response.data,
            description: "Error fetching invoices"
          };
        })
        .then(response => {
          if (response.status == 200) {
            if (status === "pending") {
              this.affiliatePendingInvoices = response.data;
            } else {
              this.affiliateInvoices = response.data;
              this.invoicesCount = response.headers["x-up-count"]; //["Content-Length"];
            }
          } else if (response.status == 400 || response.status == 403) {
            this.invoiceError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.invoiceError = { description: response.description };
          }
        });
    },
    getAddressPoints(env, brand) {
      this.isLoading = true;
      var params =
        "?type=affiliate" +
        "&brand=" +
        brand +
        "&environment=" +
        env +
        "&cost=1" +
        "&per_page=10000&page=1";
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          params += "&invoice_group=1";
        }
      }

      httpServiceUser.userService
        .getAddressPoints(
          this.affiliate.company_id,
          params,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            this.subsidiaries = response.data;
            this.isLoading = false;
            this.subsidiaries.forEach(x => {
              if (x.codcom_card) {
                this.workingPointAddress.push({
                  codcom: x.codcom_card,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
              if (
                !this.workingPointAddress.find(
                  ({ codcom }) => codcom === x.codcom
                )
              ) {
                this.workingPointAddress.push({
                  codcom: x.codcom,
                  name: x.name,
                  address: x.address.street_name + ", " + x.address.locality
                });
              }
            });
          } else if (response.status == 400 || response.status == 403) {
            return null;
          } else {
            return null;
          }
        })
        .catch(error => {
          return null;
        });
    },
    selectProduct(index) {
      this.selectedProduct = this.affiliate.products[index];
      this.getAddressPoints("both", this.selectedProduct.brand);
      this.getAffiliateInvoices("pending");
      this.affiliateInvoices = this.getAffiliateInvoices();
      // this.getDashboardGeneralInfos(this.filters.product);
    },
    selectPendingInvoice(invoice, action) {
      if (action === "pay") {
        this.selectedInvoices = [];
        this.selectedInvoicesForPay = [];
        for (let index in this.selectedInvoices) {
          if (invoice.id === this.selectedInvoices[index].id) {
            return;
          }
        }
        this.selectedInvoices.push(invoice);
        this.selectedInvoicesForPay.push(invoice);
        this.showModalPopup("modal_popup_pay", true);
      } else if (action === true) {
        for (let index in this.selectedInvoices) {
          if (invoice.id === this.selectedInvoices[index].id) {
            return;
          }
        }
        this.selectedInvoices.push(invoice);
        this.selectedInvoicesForPay.push(invoice);
      } else if (action === false) {
        for (let index in this.selectedInvoices) {
          if (invoice.id === this.selectedInvoices[index].id) {
            this.selectedInvoices.splice(index, 1);
            this.selectedInvoicesForPay.splice(index, 1);
          }
        }
      }

      this.getTotalAmountWithBalancePaid();
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    displayDate(date) {
      return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY");
    },
    selectInvoiceMobile(index) {
      this.invoiceSelectedMobile = this.selectedInvoices[index];
      this.detailsMobile = !this.detailsMobile;
    },
    invoiceForPayChecked(invoice, action) {
      this.getTotalAmountWithBalancePaid();
      this.$refs.childComponent.selectInvoiceAction(invoice, action);
    },
    customLabel({ codcom, address }) {
      return `${codcom} – ${address}`;
    },
    payInvoices() {
      this.totalAmountIsNotEnough = false;
      this.totalAmountCantBePaid = false;
      let dates = this.selectedInvoicesForPay.map(d => {
        return moment.min(moment(d.date));
      });
      let earliestInvoice = this.selectedInvoicesForPay.filter(i => {
        return moment.min(dates).isSame(moment(i.date)) ? i : null;
      })[0];
      if (this.selectedInvoicesForPay.length === 0) {
        this.totalAmountCantBePaid = true;
        return;
      }

      let minPayAmount = parseFloat(this.filterDashboardInfos(0, 'BALANCE')).toFixed(2);
      if (minPayAmount > 0){
        minPayAmount = minPayAmount < earliestInvoice.amount ? minPayAmount : earliestInvoice.amount;
      } 

      if (this.totalAmount < minPayAmount ) {
        this.totalAmountIsNotEnough = true;
        this.totalAmount = minPayAmount;
        return;
      }

      let invoiceFormatedForPay = [];
      this.paymentInvoicesError = null;
      this.paymentInvoicesSuccess = false;
      this.showModalPopup("modal_popup_pay", false);
      this.showModalPopup("modal_popup_loading", true);

      for (let index in this.selectedInvoicesForPay) {
        let tempObj = {
          invoice: this.selectedInvoicesForPay[index].invoice,
          from_source_id: this.selectedInvoicesForPay[index].id,
          amount: this.selectedInvoicesForPay[index].amount,
          date: this.selectedInvoicesForPay[index].date
        };
        invoiceFormatedForPay.push(tempObj);
      }

      let payload = {
        payment: {
          user: this.user.id,
          client_account:
            this.selectedWorkingPointAddress !== ""
              ? this.selectedWorkingPointAddress.codcom
              : this.affiliate.company_id,
          invoices: invoiceFormatedForPay,
          amount: Number(this.totalAmount)
        }
      };

      httpServiceAffiliate.affiliateService
        .payInvoices(this.affiliate.company_id, payload, Cookies.get("token"))
        .then(response => {
          if (response.status === 200) {
            this.paymentInvoicesSuccess = true;
            this.paymentInvoicesError = null;
            // this.totalAmount = this.totalPayment;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            this.redirectToPaymentPage(response.data);
            this.resetAndClose();
          } else if (response.status === 400 || response.status === 403) {
            this.paymentInvoicesError = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.paymentInvoicesError = {};
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.paymentInvoicesError = error.response.data;
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },

    getPaymentStatus(paymentId) {
      this.paymentInvoicesError = null;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceAffiliate.affiliateService
        .getPaymentOrder(
          Cookies.get("token"),
          this.affiliate.company_id,
          paymentId
        )
        .then(response => {
          if (response.status == 200) {
            this.payment = response.data;
            if (this.payment.errorCode !== 0) {
              this.payment.status = "R";
            }
            this.paymentInvoicesError = null;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_paidSuccess", true);
            this.redirectToPaymentPage(response.data);
          } else if (response.status === 400 || response.status === 403) {
            this.paymentInvoicesError = {};
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.paymentInvoicesError = {};
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.paymentInvoicesError = error.response.data;
          } else {
            this.paymentInvoicesError = {};
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    redirectToPaymentPage(response) {
      let url = process.env.VUE_APP_NETOPIA_PAYMENT;
      let params = {
        env_key: response.envelope,
        data: response.data
      };
      utils.openWindowWithPost(url, params);
    },
    resetAndClose() {
      this.showModalPopup("modal_popup_pay", false);
      this.selectedInvoices = [];
      this.selectedInvoicesForPay = [];
      this.totalAmountIsNotEnough = false;
      this.totalAmountCantBePaid = false;
      this.$refs.childComponent.resetSelectedInvoices();
    },
    updateInput(entry = null) {
      if (entry) {
        entry.amount = this.convertValueToDecimalVariable(entry.amount);
      } else {
        this.totalAmount = this.convertValueToDecimalVariable(this.totalAmount);
      }
      this.$forceUpdate();
    },
    downloadModel() {
      var formData = {
        product: 0,
        customer_id: this.affiliate.company_id,
        lang: localStorage.language,
        type:
          "AFF_OUG"
      };

      httpServiceCustomer.customerService
        .getOrderTemplateModelFile(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel",
              }
            );
            saveAs(blob, response.data.name);
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    formatInput(variable) {
      return Number(variable).toFixed(2);
    },
    convertValueToDecimalVariable(variable) {
      if (/^-?\d*[.]?\d*$/.test(variable)) {
        this.oldValue = variable;
      } else {
        variable = this.oldValue;
      }
      return variable;
    },
    getDashboardGeneralInfos(product) {
      var formData =
        "?customer_id=" +
        (this.filters.subsidiary !== "A"
          ? this.filters.subsidiary
          : this.affiliate.company_id) +
        "&fiscal=" +
        this.affiliate.fiscal_code
      if (product != 0) {
        formData += "&brand=" +
          this.filters.product;
      }
      if (this.dashboardInfos[product]){
        return;
      }
      httpServiceAffiliate.affiliateService
        .getDashboardInfos(
          this.affiliate.company_id,
          formData,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.dashboardInfos[product] = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.dashboardError = {
              code: response.data,
              description: "Error fetching dashboard info data"
            };
          } else {
            this.dashboardError = { description: response.description };
          }
        })
        .catch(error => {
          this.dashboardError = {
            code: error.response.data,
            description: "Error fetching dashboard info data"
          };
        });

    },
    getInvoicesInArchive() {
      // retreive customer beneficiaries from service
      let document = {
        company_type: "affiliate",
        brand: this.filters.product,
        start_date: this.filters.period[0],
        end_date: this.filters.period[1],
        paid:
          this.filters.invoiceStatus !== "A"
            ? [this.filters.invoiceStatus]
            : ["L", "R", "P", "U", "V"],
        sort: this.sortDirection,
        per_page: 100000,
        page: 1
      };
      if (this.affiliate.user_config && this.affiliate.user_config.config) {
        if (this.affiliate.user_config.config.invoice_group) {
          document.invoice_group = true;
        } else {
          document.invoice_group = false;
        }
      }
      if (this.filters.subsidiary !== "A") {
        document.codall = [this.filters.subsidiary];
      }

      if (this.sortDirection === "desc") {
        document.desc = [this.sortField];
      } else {
        document.asc = [this.sortField];
      }
      this.showModalPopup("modal_popup_loading", true);
      return httpServiceAffiliate.affiliateService
        .getInvoicesArchive(
          document,
          this.affiliate.company_id,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.response_content)],
              {
                type: response.data.content_type
              }
            );
            saveAs(blob, this.affiliate.company_id);
            this.showModalPopup("modal_popup_loading", false);
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.error = { code: 500 };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status == 404) {
            // show file not found
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.error = { code: 500 };

            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.error = { code: 500 };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    filterDashboardInfos(product, name) {
      for (let index in this.dashboardInfos[product]) {
        if (this.dashboardInfos[product][index].name === name) {
          return this.dashboardInfos[product][index].value;
        }
      }
      return null;
    },
    selectAllInvoicesForPay() {
      this.selectedInvoices = [];
      this.selectedInvoicesForPay = [];

      // if (
      //   !this.arrayEquals(this.affiliatePendingInvoices, this.selectedInvoices)
      // ) {
        for (let index in this.affiliatePendingInvoices) {
          if (this.affiliatePendingInvoices[index].product.card > 0 && this.affiliatePendingInvoices[index].amount > 0) {
            this.selectedInvoices.push(this.affiliatePendingInvoices[index]);
            this.selectedInvoicesForPay.push(this.affiliatePendingInvoices[index]);
          }
        }
        this.getTotalAmountWithBalancePaid();
        this.$refs.childComponent.selectAllInvoicesForPay();
        this.$forceUpdate();
      // }
      this.showModalPopup("modal_popup_pay", true);
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },
    getTotalAmountWithBalancePaid() {
      if (this.balancePaidCheck) {
        this.totalAmount =
          parseFloat(this.getTotalPayment) +
          parseFloat(this.filterDashboardInfos(0, "BALANCE_PAID"));
      } else {
        this.totalAmount = this.getTotalPayment;
      }
    },
    showInvoicesForPay() {
      this.showModalPopup("modal_popup_pay", true);
      this.getTotalAmountWithBalancePaid();
    }
  },
  mounted() {
    this.filters.period = [moment().subtract(3, "month"), moment()];
    let paidSuccessURLParam = utils.getUrlParam("orderId");
    if (paidSuccessURLParam) {
      this.getPaymentStatus(paidSuccessURLParam);
    }
    let productURL = utils.getUrlParam("product");
    if (productURL) {
      this.filters.product = utils.getProductById(
        parseInt(productURL),
        this.affiliate.products
      ).brand;
    } else {
      this.filters.product = this.affiliate.products[0].brand;
    }
    this.filters.invoiceStatus = "A";
    this.filters.subsidiary = "A";
    this.selectProduct(0);
    this.getDashboardGeneralInfos(0);
  },
  watch: {
    currentPage: function () {
      this.getAffiliateInvoices();
    }
    // totalAmount: function() {
    //   var totalPayment = 0;
    //   for (let index in this.selectedInvoicesForPay) {
    //     totalPayment += Number(this.selectedInvoicesForPay[index].amount);
    //   }
    //   this.totalAmount = totalPayment.toFixed(2);
    // }
  },
  computed: {
    getTotalPayment() {
      var totalPayment = 0;
      for (let index in this.selectedInvoicesForPay) {
        totalPayment += Number(this.selectedInvoicesForPay[index].amount);
      }
      this.totalAmount = totalPayment.toFixed(2);
      return totalPayment.toFixed(2);
    }
  }
};
</script>

<style scoped src="@/assets/styles/beneficiary.css"></style>
<style>
.balance {
  display: flex;
  align-items: center;
  max-width: 95%;
  width: 100%;
  margin: 0 auto;
}

.invoices-filter .reportrange-text {
  height: 27px;
  font-size: 13px;
  padding: 3px 10px;
  border: none !important;
  color: #585858;
  background: #f1f1f1;
}

.center {
  font-size: 16px;
  padding: 10px;
}

.pay_content {
  max-height: 250px;
  overflow: overlay !important;
}
</style>
